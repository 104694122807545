import '../App.css'
import OGrupoFirstSection from '../components/OGrupo/FirstSection'
import Footer from '../components/Home/Footer'
import OGrupoFourfhSection from '../components/OGrupo/FourfhSection'
import Header from '../components/Home/Header'
import OGrupoSecondSection from '../components/OGrupo/SecondSection'
import OGrupoThirdSection from '../components/OGrupo/ThirdSection'

export default function OGrupo() {
    return (
        <div className='oGrupo'>
            <Header
                home=''
                oGrupo='active'
                segmentos=''
                esg=''
                contato=''
            />
            <OGrupoFirstSection />
            <OGrupoSecondSection />
            <OGrupoThirdSection />
            <OGrupoFourfhSection />
            <Footer
                home=""
                oGrupo='active'
                segmentos=''
                privacidade=''
                contato=''
            />
        </div>
    )
}