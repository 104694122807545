import { Link } from 'react-router-dom'
import './style.css'

export default function HomeEightSection() {
    return (
        <div className='homeEightSection'>
            <div className='homeEightSection-area'>
                <p id="subtitleOrange">responsabilidade social</p>
                <h1>Nosso propósito</h1>
                <p>Sabendo que somos parte desse mesmo propósito, direcionamos parte <br />dos resultados de todas as operações às instituições sérias que <br />contribuem para a evolução da humanidade!</p>

                <Link to='/o-grupo'><div className='btn-laranja'>Saiba mais <i className="fa-solid fa-arrow-right"></i></div></Link>

            </div>
        </div>
    )
}