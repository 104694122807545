import { useEffect, useState } from 'react'
import SegmentoCards from './Card'
import SegmentoSegmentos from './Segmento'
import './style.css'

export default function SegmentosSecondSection() {

    const [innerWidth, setInnerWidth] = useState('')

    useEffect(() => {
        if(window.innerWidth < 1215) {
            setInnerWidth('column-reverse')
        }
    },[])

    return (
        <div className='segmentosSecondSection'>
            <div id='segmentoSecondSection' className='segmentosSecondSection-area' style={{ backgroundColor: "#E5E1E6" }}>
                <div id='engenharia' className='segmentosSecondSection-engenharia'>
                    <div className='segmentosSecondSection-engenharia-box'>
                        <SegmentoSegmentos
                            segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_ENGENHARIA.png'
                            segmentoName='engenharia'
                            segmentoText='O futuro da engenharia faz parte do Grupo AlloyMit, atuando no segmento de construção, incorporação e vendas aliando a tecnologia em prol do futuro.'
                        />
                        <SegmentoCards
                            margin='10px 40px -80px 0'
                            boxColor='#fff'
                            boxTextColor='#000'
                            boxIcon='./LOGOS-02.png'
                            boxText='Focada em excelência técnica eficiência empresarial e profundo respeito pelo ser humano, a DW Incorp foi criada para proporcionar ótimas experiências a seus clientes, parceiros e colaboradores, e, para tanto, está fundamentada sobre três pilares: inteligência de negócio, evolução tecnológica e conexão com pessoas.'
                            buttonColor='#00205B'
                            boxButton='https://dwincorp.com.br/'
                        />
                    </div>
                    <SegmentoCards
                        boxColor='#fff'
                        boxTextColor='#000'
                        boxIcon='./LOGOS-01.png'
                        boxText='Incorporadora do grupo atua fortemente com tecnologia, sendo a primeira com contratos, matricula e escritura de forma 100% digital, com pouco mais de 3 anos de existência contamos com 8 empreendimentos na carteira e um Pipeline de mais 14 novos negócios, e com um FII (Fundo de Investimentos Imobiliário) aprovado no valor de R$ 300MM.'
                        buttonColor='#00205B'
                        boxButton='https://unityengenharia.com/'
                    />
                </div>
            </div>
            <div className='segmentosSecondSection-area' style={{ backgroundColor: "#393F4E", paddingTop: "40px" }}>
                <div className='segmentosSecondSection-engenharia' style={{flexDirection: `${innerWidth}`}}>
                    <div className='segmentosSecondSection-engenharia-box'>
                        <SegmentoCards
                            margin='0 40px 40px 0'
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-03.png'
                            boxText='Reúne todas as possibilidades do mercado financeiro, um banco digital completo com soluções de Banking e meios de pagamento com maquinas POS’s e cartão de crédito, operações de créditos e empréstimos, Gateway de pagamento internacional e mesa de tesouraria para negociação de mercadorias e insumos com players dentro de arranjo fechado ou aberto, podendo integrar todas as soluções do grupo ao APP em um só ambiente.'
                            buttonColor='#6C49CD'
                            boxButton='https://rinodigital.com.br/'
                        />
                        <SegmentoCards
                            margin='0 40px 40px 0'
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-06.png'
                            boxText='Realiza análises fidedignas atribuindo score de pessoas jurídicas, utilizando inteligência artificial para reunir as principais informações para tomada de decisão das empresas, de forma coordenada com os principais birôs do país.'
                            buttonColor='#6C49CD'
                            boxButton='https://bossanalytics.com.br/'
                        />
                    </div>
                    <div id='finance' className='segmentosSecondSection-engenharia-box'>
                        <SegmentoSegmentos
                            segmentoColor='#7748D2'
                            textColor='#fff'
                            segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_FINANCE.png'
                            segmentoName='finance'
                            segmentoText='O futuro da engenharia faz parte do Grupo AlloyMit, atuando no segmento de construção, incorporação e vendas aliando a tecnologia em prol do futuro.'
                        />
                        <SegmentoCards
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-04.png'
                            boxText='A RinoBox é uma caixa de soluções tecnológicas disruptivas, especializada em transformar mercados, revolucionar empresas e escalar negócios através de tecnologia de ponta.'
                            buttonColor='#6C49CD'
                            boxButton='https://rinobox.com.br/'
                        />
                        <SegmentoCards
                            margin='40px 0px -80px 0'
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-05.png'
                            boxText='Empresa do ramo de corretagem de seguros que trabalha com a análise das informações da companhia para redução de riscos na contratação dos seguros personalizados, adequando o valor do prêmio a pagar gerando redução de custos Atua com o atendimento de pessoa física e jurídica, bem como ao mercado, com soluções em seguros e programas de benefícios.'
                            buttonColor='#6C49CD'
                            boxButton='http://alloyseg.com.br/'
                        />
                    </div>

                </div>
            </div>
            <div className='segmentosSecondSection-area' style={{ backgroundColor: "#E5E1E6" }}>
                <div className='segmentosSecondSection-engenharia'>
                    <div id='consultoria' className='segmentosSecondSection-engenharia-box'>
                        <SegmentoSegmentos
                            segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_CONSULTORIA.png'
                            segmentoName='CONSULTORIA, IMPOSTOS E AUDITORIA ATUANDO'
                            segmentoText='Consultoria empresarial de impostos, M&A e Due Diligence, fazem parte do universo de soluções desruptivas e estratégicas que o Grupo AlloyMit oferece em suas atuações.'
                        />
                        <SegmentoCards
                            margin='10px 40px -80px 0'
                            boxColor='#fff'
                            boxTextColor='#000'
                            boxIcon='./LOGOS-07.png'
                            boxText='Empresa de consultoria e negócios integrado, tendo como diferencial o foco em trabalhos Taylor -made e estratégia por execução, alicerçadas em criar soluções reais e estabelecer relações de confiança.'
                            buttonColor='#00205B'
                            boxButton='https://alloymit.com/segmentos'
                        />
                    </div>
                    <SegmentoCards
                        boxColor='#fff'
                        boxTextColor='#000'
                        boxIcon='./LOGOS-08.png'
                        boxText='Atuando há 13 anos na área de Compliance Tributário, com auditorias e a Revisão fiscal, gerando um elemento essencial para as empresas, caixa imediato, contando com 64 unidades em 19 estados do país.'
                        buttonColor='#00205B'
                        boxButton='https://letterconsulting.com.br/'
                    />
                </div>
            </div>
            <div className='segmentosSecondSection-area' style={{ backgroundColor: "#393F4E", paddingTop: "40px" }}>
                <div id='franquias' className='segmentosSecondSection-engenharia' style={{flexDirection: `${innerWidth}`}}>
                    <div className='segmentosSecondSection-engenharia-box'>
                        <SegmentoCards
                            margin='0 40px 40px 0'
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-09.png'
                            boxText='Empresa. responsável pela expansão, implantação e suporte das operações comerciais das unidades franqueadas, através da replicação do modelo comercial estruturado e certificado pela
                        Letter Consulting .'
                            buttonColor='#00B5E2'
                            boxButton='https://www.alloyfranchising.com.br/'
                        />
                    </div>
                    <div className='segmentosSecondSection-engenharia-box'>
                        <SegmentoSegmentos
                            segmentoColor='#00B5E2'
                            textColor='#fff'
                            segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_FRANQUIAS.png'
                            segmentoName='franquias'
                            segmentoText='A equipe multidisciplinar do Grupo AlloyMit permite uma expansão e gestão inteligente e estratégica, proporcionando ideias e soluções sob medida para você e seu negócio.'
                        />
                        <SegmentoCards
                            margin='10px 40px -80px 0'
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-10.png'
                            boxText='Atua com foco na estruturação e expansão de negócios promissores do mercado, através do sistema de franquias. Além de ofertar linhas de crédito para viabilizar a adesão de novos franqueados com a ampliação da quantidade de unidades.'
                            buttonColor='#00B5E2'
                            boxButton='https://alloymit.com/segmentos'
                        />
                    </div>
                </div>
            </div>
            <div className='segmentosSecondSection-area' style={{ backgroundColor: "#E5E1E6" }}>
                <div className='segmentosSecondSection-engenharia'>
                    <div id='terceirizacao' className='segmentosSecondSection-engenharia-box'>
                        <SegmentoSegmentos
                            segmentoColor='#FC4C02'
                            textColor='#000'
                            segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_TERCEIRIZACAO.png'
                            segmentoName='terceirização'
                            segmentoText='Desenvolvimento inteligente utilizando de estratégias elaboradas de acordo com seus objetivos, o Grupo AlloyMit oferece serviços de BPS, BPO e consultoria especializada para seu negócio.'
                        />
                        <SegmentoCards
                            margin='10px 40px -80px 0'
                            boxColor='#fff'
                            boxTextColor='#000'
                            boxIcon='./LOGOS-12.png'
                            boxText='A RS Supply foi fundada para atuar em diversos segmentos do mercado com a utilização das tecnologias inovadoras, desenvolvimento de soluções de sistemas integrados no segmento de facilities. Atuação em conservação e manutenção predial, com
                            fornecimento de mão de obra técnica e especializada.'
                            buttonColor='#FC4C02'
                            boxButton='https://www.rssupply.com.br/'
                        />
                    </div>
                    <SegmentoCards
                        boxColor='#fff'
                        boxTextColor='#000'
                        boxIcon='./LOGOS-11.png'
                        boxText='Uma empresa de BPO e consultoria especializada que atua na resolução dos problemas mais complexos em relação aos processos de negócios, de maneira coordenada e integrada. A Hub apresenta os melhores cenários para tomadas de decisões, a um click com a precisão das demonstrações fiscais e financeiras da sua empresa.'
                        buttonColor='#FC4C02'
                        boxButton='https://hubsc.com.br/'
                    />
                </div>
            </div>
            <div className='segmentosSecondSection-area' style={{ backgroundColor: "#393F4E", paddingTop: "40px" }}>
                <div className='segmentosSecondSection-engenharia'  style={{flexDirection: `${innerWidth}`}}>
                    <div className='segmentosSecondSection-engenharia-box'>
                        <SegmentoCards
                            margin='0 40px 40px 0'
                            boxColor='#2A2E39'
                            boxTextColor='#fff'
                            boxIcon='./LOGOS-13.png'
                            boxText='Proporciona as empresas a sua própria telefonia com sua marca no chip, facilitando a comunicação com seus clientes, gerando dados e informações relevantes para sua fidelização.'
                            buttonColor='#F2CD00'
                            boxButton='https://dwincorp.com.br/'
                        />
                    </div>
                    <div id='telecom' className='segmentosSecondSection-engenharia-box'>
                        <SegmentoSegmentos
                            segmentoColor='#F2CD00'
                            textColor='#fff'
                            segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_TELECOM.png'
                            segmentoName='telecom'
                            segmentoText='A comunicação eficiente faz parte do Grupo AlloyMit, com o MVNO, chips, redes e aplicativos, reafirmamos a ideia de que conectar e inovar é fundamental para o futuro.'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


        // <TextBox
        // marginTop="-50px"
        // paddingTop="130px"
        // backgroundColor=''
        // way='row-reverse'
        // marginRight='0px'
        // marginLeft='40px'
        // textColor='#FFF'
        // boxColor='#2A2E39'
        // boxTextColor='#fff'
        // segmentoColor='#7748D2'
        // segmentoIcon='./ALLOYMIT_SEGMENTOS_ICONES_FINANCE.png'
        // segmentoName='finance'
        // segmentoText='O segmento de Finance, oferece soluções financeiras inteligentes, Open Banking e seguros de acordo com o alto padrão de qualidade consolidado do Grupo AlloyMit.'
        // box1Icon='./LOGOS-04.png'
        // box1Text='Focada em excelência técnica eficiência empresarial e profundo respeito pelo ser humano, a DW Incorp foi criada para proporcionar ótimas experiências a seus clientes, parceiros e colaboradores, e, para tanto, está fundamentada sobre três pilares: inteligência de negócio, evolução tecnológica e conexão com pessoas.'
        // box1Button='https://dwincorp.com.br/'
        // box2Icon='./LOGOS-03.png'
        // box2Text='Incorporadora do grupo atua fortemente com tecnologia, sendo a primeira com contratos, matricula e escritura de forma 100% digital, com pouco mais de 3 anos de existência contamos com 8 empreendimentos na carteira e um Pipeline de mais 14 novos negócios, e com um FII (Fundo de Investimentos Imobiliário) aprovado no valor de R$ 300MM.'
        // box2Button='https://unityengenharia.com/'
        // />
