import './style.css'
import { Link } from 'react-router-dom'

export default function HomeSecondSection() {

    return (
        <div className='homeSecondSection'>
            <div className='homeSecondSection-area'>
                <img id='homeSecondSection' src='./grupoAlloy-img.png' alt='Imagem do Grupo AlloyMit'/>
                <div className='homeSecondSection-texto'>
                    <p id='subtitleOrange'>grupo alloymit</p>
                    <h1>somos um grupo de essência genuína e propósito responsável</h1>
                    <p>Conexão é a palavra que representa a essência do Grupo AlloyMit. Queremos nos conectar com o que há de melhor nas pessoas. Por isso, acreditamos no trabalho multidisciplinar entre as equipes como forma de germinar ideias inovadoras e soluções criativas para aprimorar experiências. O Grupo AlloyMit integra pautas e politicas ambientais, sociais e de governança corporativa de forma estruturada, consistente e consciente. Somos movidos por tecnologia, inovação e inspiração, dessa forma, estamos em constante evolução a fim de cumprir com o nosso propósito de transformar vidas e negócios.</p>
                    <Link to='/o-grupo' className='btn-laranja'>Saiba mais <i className="fa-solid fa-arrow-right"></i></Link>
                </div>
            </div>
        </div>
    )
}