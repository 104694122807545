import './style.css'

export default function ContatoSecondSection() {
    return (
        <div className='ContatoSecondSection'>
            <div className='ContatoSecondSection-area'>
                <div className='ContatoSecondSection-salaDeImprensa'>
                    <span>assessoria</span>
                    <h1>sala de imprensa</h1>
                    <p><strong>Esta é uma área dedicada especialmente para o atendimento à imprensa:</strong></p>
                    <p>Navegando abaixo você encontra os contatos para falar diretamente com nossa assessoria de imprensa.</p>
                    <p><strong>assessoria institucional</strong></p>
                    <div className='footer-contatos' >
                        <div className='footer-mailPhone'>
                            <div className='footer-phone' style={{ color: "#000" }}>
                                <i className="fa-solid fa-phone"></i>
                                <p>+55 (11) 3257-1717</p>
                            </div>
                            <div className='footer-mail' style={{ color: "#000" }}>
                                <i className="fa-solid fa-envelope"></i>
                                <p>contato@alloymit.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img id='contatoSecondSection-img' src='./contatoSecondSection-img.png' alt='img' width={600}/>
            </div>
        </div>
    )
}