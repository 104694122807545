export const desafios = [
    {
        text: '1. Erradicação da Pobreza',
        color: '#E5243B'
    },
    {
        text: '2. Fome zero e agricultura sustentável',
        color: '#DDA83A'
    },
    {
        text: '4. Educação de qualidade',
        color: '#C5192D'
    },
    {
        text: '5. Igualdade de gênero',
        color: '#FF3A21'
    },
    {
        text: '6. Água potável e saneamento',
        color: '#26BDE2'
    },
    {
        text: '7. Energia limpa e acessível',
        color: '#FCC30B'
    },
    {
        text: '8. Trabalho decente e crescimento econômico',
        color: '#A21942'
    },
    {
        text: '9. Indústria, inovação e infraestrutura',
        color: '#FD6925'
    },
    {
        text: '11. Cidades e comunidades sustentáveis',
        color: '#FD9D24'
    },
    {
        text: '16. Paz, Justiça e Instituições Eficazes',
        color: '#00689D'
    },
]