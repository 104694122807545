import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/css/pagination'
import './style.css'
import 'swiper/css'
import { useEffect } from 'react';
import { useState } from 'react';

SwiperCore.use([Autoplay, Pagination]);

const imagem = [
    {
        imagem: './ALLOYMIT_IMG_AFRICA_1.png'
    },
    {
        imagem: './ALLOYMIT_IMG_AFRICA_2.png'
    },
    {
        imagem: './ALLOYMIT_IMG_AFRICA_3.png'
    },
    {
        imagem: './ALLOYMIT_IMG_AFRICA_4.png'
    },
    {
        imagem: './ALLOYMIT_IMG_AFRICA_5.png'
    },
    {
        imagem: './ALLOYMIT_IMG_AFRICA_6.png'
    },
    {
        imagem: './ALLOYMIT_IMG_AFRICA_7.png'
    },
]

export default function EsgFifthSection() {

    const [toPerView, setToPerView] = useState(3)

    useEffect(() => {
        if(window.innerWidth < 1155) {
            setToPerView(2)
        }
    },[])

    return (
        <div className='esgFifthSection'>
            <div className='esgFifthSection-area'>
                <div className='esgFifthSection-box'>
                    <div className='esgFifthSection-text'>
                        <span>causa: educação</span>
                        <h1>escola africa</h1>
                        <p>Causa: Educação para crianças órfãs e desamparadas.</p>
                        <p><strong>Instituto AlloyMit África</strong></p>
                        <ul>
                            <li>Constituição do Instituto Letter para construção de 2 escolas na África, País de Moçambique.</li>
                            <li>1º na cidade de Beira, localizado numa região sem infraestrutura e urbanização, possui atendimento de escolas públicas para cerca de 15% da demanda escolar da região.</li>
                        </ul>
                        <p>Atuamos com educação infantil com crianças da região que não conseguem vagas na escola pública, com intuito de alfabetizar com ensino de qualidade e por princípios, educar, alimentar e dar esperança as crianças africanas.</p>
                        <ul>
                            <li>2º na cidade de Mocuba, localizado numa região isolada, onde as famílias se utilizavam do rio da região com água insalubre para as necessidades de alimentação e fisiológicas.</li>
                        </ul>
                        <p>Atuamos com educação infantil com crianças da região que não conseguem vagas na escola pública, com intuito de alfabetizar com ensino de qualidade e por princípios, educar, alimentar e dar esperança as crianças africanas.</p>
                        <p>Hoje temos um poço artesiano que abastece todas as famílias do entorno com água limpa.</p>
                    </div>
                    <div className='esgFifthSection-img'>
                        <img src='./esgFifthSection-img.png' alt='' width={450} />
                    </div>
                </div>
                <div className='esgFourthSection-carousel'>
                    <Swiper
                        slidesPerView={toPerView}
                        grabCursor={true}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                        pagination={{ clickable: true }}
                    >
                        {imagem.map((element, index) => (
                            <SwiperSlide key={index} >

                                <img src={element.imagem} alt='' width={300} />

                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}