import { Link } from 'react-router-dom'
import './style.css'

export default function SegmentosThirdSection() {
    return (
        <div className='thirdSection'>
            <div className='thirdSection-area'>
                <p id="subtitleGreen">ESG</p>
                <h1>responsabilidade social</h1>
                <p>Sabendo que somos parte desse mesmo propósito, direcionamos parte dos <br />resultados de todas as operações às instituições sérias que <br />contribuem para a evolução da humanidade!</p>

                <Link to='/o-grupo'><div className='btn-verde'>Saiba mais <i className="fa-solid fa-arrow-right"></i></div></Link>

            </div>
        </div>
    )
}