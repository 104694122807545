import './style.css'

export default function EsgThirdSection() {
    return (
        <div className='esgThirdSection'>
            <div className='esgThirdSection-area'>
                <img src='./esgThirdSection-img.png' alt='img' width={550} />
                <div className='esgThirdSection-text'>
                    <h1>compromissos esg</h1>
                    <p>Nosso compromisso destaca o apoio a projetos sociais, ambientais, culturais, esportivos e de negócios, e para validação e controle do modelo de gestão, estamos em processo de implantação de um conjunto de indicadores para a carteira de projetos socioambientais.</p>
                    <p>Entre os principais resultados atingidos estão o apoio a causas sociais e certificações de qualidade nos projetos dos empreendimentos do grupo. Os projetos sociais apoiados pelo grupo na Aldeia do Futuro beneficiam diretamente cerca de mil crianças, adolescentes e idosos por ano, acreditamos que investimento social traz alto retorno para a sociedade, pois gera impacto direto na vida dos atendidos.</p>
                    <span>Conheça abaixo alguns projetos que apoiamos:</span>
                </div>
            </div>
        </div>
    )
}