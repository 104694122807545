import { useRef, useState } from 'react'
import './style.css'
import { carouselArray } from './carousel'

export default function HomeSixSection() {

    const [sliderCount, setSliderCount] = useState(0)
    const animationRef = useRef(null)

    function nextSlider() {
        animationRef.current.style.animation = 'nextSlider 1s ease-in-out';
        if (sliderCount < carouselArray.length - 1) {
            setSliderCount(sliderCount + 1)
        } else {
            setSliderCount(0)
        }
        setTimeout(() => {
            animationRef.current.style.animation = '';
        }, 900);
    }

    function prevSlider() {
        animationRef.current.style.animation = 'prevSlider 1s ease-in-out';
        if (sliderCount > 0) {
            setSliderCount(sliderCount - 1)
        } else {
            setSliderCount(carouselArray.length - 1)
        }
        setTimeout(() => {
            animationRef.current.style.animation = '';
        }, 900);
    }

    return (
        <div className='homeSixSection'>
            <div className='homeSixSection-area'>
                <i onClick={prevSlider} className="fa-solid fa-chevron-left"></i>
                <div ref={animationRef} className='homeSixSection-card'>
                <div className='homeSixSection-texto'>
                    <div className='homeSixSection-frase'>
                        <img src='ASPAS.png' alt='aspas' />
                        <p>{carouselArray[sliderCount].frase}</p>
                    </div>
                    <div className='homeSixSection-cargo'>
                        <h1>{carouselArray[sliderCount].nome}</h1>
                        <p>{carouselArray[sliderCount].cargo}</p>
                    </div>
                </div>
                <div className='homeSixSection-foto'>
                    <img src={carouselArray[sliderCount].foto} alt='' />
                </div>
                </div>
                <i onClick={nextSlider} className="fa-solid fa-chevron-right"></i>
            </div>
        </div>
    )
}