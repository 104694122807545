import { Link } from 'react-router-dom'
import './style.css'

export default function Header(props) {

    function changeMenu() {
        const menu = document.querySelector('.header-menu-mobile-list')
        menu.classList.toggle('hide')
    }

    return (
        <div className='header'>
            <div className='header-area'>
                <a href='/'><img src='./logo.png' alt='Logo da AlloyMit' /></a>
                <div className='header-menu'>
                    <ul>
                        <li><Link to='/' className={props.home} >home</Link></li>
                        <li><Link to='/o-grupo' className={props.oGrupo} >o grupo</Link></li>
                        <li><Link to='/segmentos' className={props.segmentos} >segmentos</Link></li>
                        <li><Link to='/esg' className={props.esg} >esg</Link></li>
                        <li><Link to='/contato' className={props.contato} >contato</Link></li>
                    </ul>
                    <div className='header-sociais'>
                        <a href='https://www.instagram.com/grupoalloymit/' rel='noreferrer'><i className="fa-brands fa-instagram"></i></a>
                        <a href='https://www.facebook.com/people/Grupo-AlloyMit/100075791001091/' rel='noreferrer'><i className="fa-brands fa-facebook"></i></a>
                        <a href='https://www.linkedin.com/company/grupo-alloy-mit/mycompany/' rel='noreferrer'><i className="fa-brands fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className='header-menu-mobile'>
                    <img src='./header-menu-mobile.png' alt='header-menu-mobile' style={{width: '32px'}} onClick={changeMenu}/>
                    <div className='header-menu-mobile-list hide'>
                        <ul>
                            <li><Link to='/' className={props.home} >home</Link></li>
                            <li><Link to='/o-grupo' className={props.oGrupo} >o grupo</Link></li>
                            <li><Link to='/segmentos' className={props.segmentos} >segmentos</Link></li>
                            <li><Link to='/esg' className={props.esg} >esg</Link></li>
                            <li><Link to='/contato' className={props.contato} >contato</Link></li>
                        </ul>
                    </div>

                    <div className='header-sociais'>
                        <a href='https://www.instagram.com/grupoalloymit/' rel='noreferrer'><i className="fa-brands fa-instagram"></i></a>
                        <a href='https://www.facebook.com/people/Grupo-AlloyMit/100075791001091/' rel='noreferrer'><i className="fa-brands fa-facebook"></i></a>
                        <a href='https://www.linkedin.com/company/grupo-alloy-mit/mycompany/' rel='noreferrer'><i className="fa-brands fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}