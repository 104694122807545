import '../App.css'
import ContatoFirstSection from '../components/Contato/FirstSection'
import ContatoSecondSection from '../components/Contato/SecondSection'
import Footer from '../components/Home/Footer'
import Header from '../components/Home/Header'

export default function Contato() {
    return (
        <div className='contato'>
            <Header
                home=''
                oGrupo=''
                segmentos=''
                esg=''
                contato='active'
            />
            <ContatoFirstSection />
            <ContatoSecondSection />
            <Footer
                home=""
                oGrupo=''
                segmentos=''
                privacidade=''
                contato='active'
            />
        </div>
    )
}