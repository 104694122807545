import { Link } from 'react-router-dom'
import './style.css'

export default function Footer(props) {

    const ano = new Date().getFullYear()

    return (
        <div className='footer'>
            <div className='footer-area'>
                <div className='footer-menuArea'>
                    <img src='./logo.png' alt='Logo AlloyMit'/>
                    <div className='footer-menu'>
                        <ul>
                        <li><Link to='/' className={props.home} onClick={window.scrollTo(0, 0)}>home</Link></li>
                            <li><Link to='/o-grupo' className={props.oGrupo} onClick={window.scrollTo(0, 0)}>o grupo</Link></li>
                            <li><Link to='/segmentos' className={props.segmentos} onClick={window.scrollTo(0, 0)}>segmentos</Link></li>
                            <li><Link to='/privacidade-e-cookies' className={props.privacidade} onClick={window.scrollTo(0, 0)}>privacidade e cookies</Link></li>
                            <li><a href='./codigo-de-conduta.pdf' target='_blank'>código de conduta</a></li>
                            <li><Link to='/contato' className={props.contato} onClick={window.scrollTo(0, 0)}>contato</Link></li>
                        </ul>
                    </div>
                </div>
                <div className='footer-contatosArea'>
                    <div className='footer-contatos'>
                        <div className='footer-endereco'>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>ed. alloymit<br/>rua bartolomeu de gusmão, 290<br/>vila mariana - sp - cep: 04111-020</p>
                        </div>
                        <div className='footer-mailPhone'>
                            <div className='footer-phone'>
                                <i className="fa-solid fa-phone"></i>
                                <p>+55 (11) 3257-1717</p>
                            </div>
                            <div className='footer-mail'>
                                <i className="fa-solid fa-envelope"></i>
                                <p>contato@alloymit.com</p>
                            </div>
                        </div>
                    </div>
                    <div className='footer-sociais'>
                        <a href='https://www.instagram.com/grupoalloymit/' rel='noreferrer'><i className="fa-brands fa-instagram"></i></a>
                        <a href='https://www.facebook.com/people/Grupo-AlloyMit/100075791001091/' rel='noreferrer'><i className="fa-brands fa-facebook"></i></a>
                        <a href='https://www.linkedin.com/company/grupo-alloy-mit/mycompany/' rel='noreferrer'><i className="fa-brands fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className='footer-copy'>
                    <p>© copyright {ano} – AlloyMit. Todos os direitos reservados.</p>
                </div>
            </div>
        </div>
    )
}