import './style.css'

export default function OGrupoSecondSection() {
    return (
        <div className='oGrupoSecondSection'>
            <div className='oGrupoSecondSection-area'>
                <img id='oGrupoSecondSection' src='./ogrupo-img1.png' alt=''/>
                <div className='oGrupoSecondSection-texto'>
                    <p id='subtitleOrange'>grupo alloymit</p>
                    <h1>gerir com responsabilidade faz parte de nosso dna</h1>
                    <p>Somos uma empresa global com presença local, atuando de forma multissetorial. Possuímos uma carteira de participações e investimentos composta por empresas em diferentes setores da economia em diversos estágios de desenvolvimento. Nossa busca é incansável para encontrar oportunidades de melhorar e gerar eficiência nos segmentos que atuamos. A experiência e network adquiridos ao longo do tempo geram diferenciais competitivos ao nosso grupo, somados ao alto grau de comprometimento de nossa equipe numa cultura hard-working e hands-on. Fomentamos o cross-selling entre todas as áreas de negócios internos e externos dos times, que nos posiciona em um patamar diferenciado da média do mercado.</p>
                    <p>Inovação e tecnologia assumem um papel estratégico no posicionamento competitivo do Grupo AlloyMit e traduzem-se num exercício constante de concepção, experimentação e implementação de novas ideias e soluções que permitem o desenvolvimento de negócios competitivos, que garantem a criação de valor para todos os stakeholders.</p>
                </div>
            </div>
            <div className='oGrupoSecondSection-area2'>
                <img id='oGrupoSecondSection' src='./ogrupo-img2.png' alt=''/>
                <div className='oGrupoSecondSection-texto'>
                    <h1>onde atuamos</h1>
                    <p>Trabalhamos com metas audaciosas de crescimento e desempenho, sempre com elevados padrões de controles operacionais e de risco. Os processos de gestão do Grupo AlloyMit permitem tratar cada empresa como única. Buscamos performances extraordinárias implantando estratégias, processos e métricas com bonificações como forma de incentivo no alcance de nossas metas.</p>
                    <p>Dentro deste contexto, o capital humano da AlloyMit é formado por uma equipe com perfis e competências complementares, alinhados à nossa cultura. Apoiamos fortemente a equipe de gestão das empresas investidas, auxiliando-as diariamente na geração de crescimento sustentável a médio e longo prazos.</p>
                </div>
            </div>
        </div>
    )
}