import { useState } from 'react'
import { desafios } from './buttonsComponent'
import { ModalText } from './modalComponents'
import './style.css'



export default function EsgSixthSection() {

    const [indexState, setIndexState] = useState(0)

    function modalOpenClose () {
        const close = document.querySelector('.esgSixthSection-modal')
        close.classList.toggle('close')
    }

    return (
        <div className='esgSixthSection'>
            <div className='backgroundModal'></div>
            <div className='esgSixthSection-area'>
                <div className='esgSixthSection-box'>
                    <div className='esgSixthSection-img'>
                        <img src='./esgSixthSection-img.png' alt='' width={550} />
                    </div>
                    <div className='esgSixthSection-text'>
                        <h1>desafio para 2022</h1>
                        <p>Fortalecer o comitê semanal do ESG, trazendo novos participantes representantes dos diversos departamentos e empresas do grupo Alloy Mit, e assim avançar de maneira firme na implementação de metas ambientais auto definidas no programa ESG. Definir e aferir as métricas de indicadores de sustentabilidade nas diferentes etapas operacionais e empresas do grupo.</p>
                        <p>Treinamento e conscientização contínuo dos colaboradores dos programas e metas ESG para o grupo, pois só teremos resultado se caminharmos juntos. Trabalhar os 17 objetivos de desenvolvimento sustentáveis apresentados pela ONU como diretrizes que devem ser perseguidas, abordando os principais desafios para o desenvolvimento enfrentados por pessoas no Brasil e no mundo.</p>
                    </div>
                </div>
                <div className='esgSixthSection-modal close'>
                    <div className='esgSixthSection-modal-header' style={{ backgroundColor: `${ModalText[indexState].backgroundColor}` }}>
                        <div className='esgSixthSection-modal-header-button' onClick={modalOpenClose}>
                        <img src='./esgSixthSection-modal-cross.png' alt='modal-cross' width={16}  />
                        </div>
                        <h1>{ModalText[indexState].title}</h1>
                    </div>
                    <div className='esgSixthSection-modal-body'>
                        <ul>
                            {ModalText[indexState].numero1 ? <li><strong>{ModalText[indexState].numero1}</strong> {ModalText[indexState].texto1}</li> : null}
                            {ModalText[indexState].numero2 ? <li><strong>{ModalText[indexState].numero2}</strong> {ModalText[indexState].texto2}</li> : null}
                            {ModalText[indexState].numero3 ? <li><strong>{ModalText[indexState].numero3}</strong> {ModalText[indexState].texto3}</li> : null}
                            {ModalText[indexState].numero4 ? <li><strong>{ModalText[indexState].numero4}</strong> {ModalText[indexState].texto4}</li> : null}
                            {ModalText[indexState].numero5 ? <li><strong>{ModalText[indexState].numero5}</strong> {ModalText[indexState].texto5}</li> : null}
                            {ModalText[indexState].numero6 ? <li><strong>{ModalText[indexState].numero6}</strong> {ModalText[indexState].texto6}</li> : null}
                            {ModalText[indexState].numero7 ? <li><strong>{ModalText[indexState].numero7}</strong> {ModalText[indexState].texto7}</li> : null}
                            {ModalText[indexState].numero8 ? <li><strong>{ModalText[indexState].numero8}</strong> {ModalText[indexState].texto8}</li> : null}
                            {ModalText[indexState].numero9 ? <li><strong>{ModalText[indexState].numero9}</strong> {ModalText[indexState].texto9}</li> : null}
                            {ModalText[indexState].numero10 ? <li><strong>{ModalText[indexState].numero10}</strong> {ModalText[indexState].texto10}</li> : null}
                            {ModalText[indexState].numero11 ? <li><strong>{ModalText[indexState].numero11}</strong> {ModalText[indexState].texto11}</li> : null}
                            {ModalText[indexState].numero12 ? <li><strong>{ModalText[indexState].numero12}</strong> {ModalText[indexState].texto12}</li> : null}
                            {ModalText[indexState].numero13 ? <li><strong>{ModalText[indexState].numero13}</strong> {ModalText[indexState].texto13}</li> : null}
                        </ul>
                    </div>
                </div>
                <div className='esgSixthSection-desafios'>
                    {desafios.map((element, index) => (
                        <div key={index} aria-valuenow={index} className='esgSixthSection-desafios-cards' style={{ backgroundColor: `${element.color}` }} onClick={(e) => {
                            setIndexState(e.currentTarget.ariaValueNow);
                            modalOpenClose()
                            }}>
                            <p style={{ color: "#fff" }}>{element.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}