import './style.css'

export default function ContatoFirstSection() {
    return (
        <div className='contatoFirstSection'>
            <div className='contatoFirstSection-area'>
                <div className='contatoFirstSection-faleConosco'>
                    <span>Contato</span>
                    <h1>fale conosco</h1>
                    <p><strong>Entre em contato conosco:</strong></p>
                    <p>Estamos prontos para atender você com excelência criando soluções sob medida<br />para seu negócio. Mande uma mensagem para nós:</p>
                    <div className='footer-contatos' >
                        <div className='footer-endereco' style={{ color: "#000" }}>
                            <i className="fa-solid fa-location-dot"></i>
                            <p>ed. alloymit<br />rua bartolomeu de gusmão, 290<br />vila mariana - sp - cep: 04111-020</p>
                        </div>
                        <div className='footer-mailPhone'>
                            <div className='footer-phone' style={{ color: "#000" }}>
                                <i className="fa-solid fa-phone"></i>
                                <p>+55 (11) 3257-1717</p>
                            </div>
                            <div className='footer-mail' style={{ color: "#000" }}>
                                <i className="fa-solid fa-envelope"></i>
                                <p>contato@alloymit.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contatoFirstSection-forms'>
                    <form id="llCaptureForm" class="llform001" action="//paginas.rocks/capture" method="post">
                        <input type="hidden" id="mid" name="mid" value="691430" />
                        <input type="hidden" id="fid" name="fid" value="58800" />
                        <input type="hidden" id="formType" name="formType" value="dynamic" />
                        <div className='contatoFirstSection-forms-inputs'>
                            <div style={{width: '100%'}}>
                                <input type="text" id="llfield69862" name="llfield69862" placeholder="Digite seu nome" />
                                <div class="field-error" id="llerror69862"></div>
                            </div>
                            <div style={{width: '100%'}}>
                                <input type="email" id="llfield69863" name="llfield69863" placeholder="Digite seu e-mail" data-required="true" />
                                <div class="field-error" id="llerror69863"></div>
                            </div>
                        </div>
                        <div className='contatoFirstSection-forms-inputs'>
                            <div style={{width: '100%'}}>
                                <input type="tel" id="llfield69864" name="llfield69864" placeholder="Digite DDD + Seu Número" />
                                <div class="field-error" id="llerror69864"></div>
                            </div>
                            <div style={{width: '100%'}}>
                                <select id="llfield87673" name="llfield87673">
                                    <option value="">Assunto</option>
                                    <option value="Informação">Informação</option>
                                    <option value="Outros assuntos">Outros assuntos</option>
                                    <option value="Parceria">Parceria</option>
                                </select>
                                <div class="field-error" id="llerror87673"></div>
                            </div>
                        </div>
                        <div>
                            <textarea id="llfield78545" name="llfield78545" placeholder="Digite sua mensagem"></textarea>
                            <div class="field-error" id="llerror78545"></div>
                        </div>
                        <div class="button-bar">
                            <button type="submit">Enviar</button>
                        </div>
                        <div class="loading-box" style={{ display: 'none' }}>
                            <img src="//paginas.rocks/content/images/spinner.gif" alt='' />
                            <div>loading...</div>
                        </div>
                        <div class="error-box" style={{ display: 'none' }}>
                            <img src="//paginas.rocks/content/images/error-icon.png" alt='' />
                            <div></div>
                            <button type="button" class="btn" id="btn-error">Ok</button>
                        </div>
                        <img src="https://llimages.com/redirect/redirect.aspx?A=V&f=58800&m=691430" alt='' style={{ display: 'none' }} />
                    </form>
                </div>
            </div>
        </div>
    )
}