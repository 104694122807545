import './style.css'

export default function OGrupoThirdSection() {

    function acordeon(e) {
        const angleDown = e.currentTarget.querySelector('#angleDown')
        const acordeon = e.currentTarget.querySelector('.show')

        allAcordeon()
        allArrowDown()
        acordeon.classList.toggle('hide')
        angleDown.classList.toggle('opened')
        if(acordeon.className === "show hide") {angleDown.classList.remove('opened')}
    }

    function allAcordeon() {
        const allAngleDown = document.querySelectorAll('.opened')
        allAngleDown.forEach((elemento) => {
            elemento.classList.remove('opened')
        })
    }

    function allArrowDown() {
        const allAngleDown = document.querySelectorAll('.show')
        allAngleDown.forEach((elemento) => {
            elemento.classList.add('hide')
        })
    }

    return (
        <div className='oGrupoThirdSection'>
            <div className='oGrupoThirdSection-area'>
                <img id='sobreALetterSegmentos' src='./segmento-img.png' alt='Segmentos' />
                <div className='oGrupoThirdSection-box'>
                    <h1>segmentos</h1>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                                <p> <span style={{backgroundColor: "#8F8B90"}}></span>engenharia</p><img src='./down-arrow-angle.png' id='angleDown' alt='Arrow Down' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Construção, incorporação e vendas</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#7748D2"}}></span>finance</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Open banking, serviços e seguros</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#EE2737"}}></span>investimento</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Mesa proprietária de investimento e programas educacionais</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#00B5E2"}}></span>franquias</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Gestão e expansão</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#FC4C02"}}></span>terceirização</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>BPS, BPO e Consultoria especializada</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#F2CD00"}}></span>telecom</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Chips, rede e aplicativo</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#00205B"}}></span>consultoria, impostos e auditoria</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Consultoria empresarial impostos, M&A e Due Diligence</p>
                            </div>
                        </div>
                    </div>
                    <div className='oGrupoThirdSection-acordeon'>
                        <div className='oGrupoThirdSection-itens' onClick={(e) => acordeon(e)}>
                            <div className='oGrupoThirdSection-card'>
                            <p> <span style={{backgroundColor: "#30B700"}}></span>social</p><img src='./down-arrow-angle.png' alt='Arrow Down' id='angleDown' height={15} />
                            </div>
                            <div className='show hide'>
                                <p>Transformação de vidas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}