import { Link } from 'react-router-dom'
import './style.css'

export default function HomeThirdSection() {

    function goTo(view) {
        setTimeout(() => {
            document.getElementById(`${view}`).scrollIntoView()
            console.log('tese')
        }, 800);
    }

    return (
        <div className='homeThirdSection'>
            <div className='homeThirdSection-area'>
                <div className='homeThirdSection-texto'>
                    <p id='subtitleOrange'>segmentos</p>
                    <h1>posicionamento <br />estratégico e competitivo</h1>
                    <p>O Grupo AlloyMit conduz seus negócios de forma estratégica, com alta
                        performance e foco em resultados. Nossa atuação visa metas audaciosas de
                        crescimento e desempenho, sempre respeitando elevados padrões de controles
                        operacionais e de risco.</p>

                </div>
                <div className='segmentosIcons'>
                    <div className='segmentosIcons-1'>
                        <div className='engenharia'>
                            <Link to='/segmentos' onClick={() => goTo("engenharia")}>
                                <img id='icons1' src='./ENGENHARIA-1.png' alt='' />
                                <img id='icons2' src='./ENGENHARIA-2.png' alt='' />
                            </Link>
                        </div>
                        <div className='finance'>
                            <Link to='/segmentos' onClick={() => goTo("finance")}>
                                <img id='icons1' src='./FINANCE-1.png' alt='' />
                                <img id='icons2' src='./FINANCE-2.png' alt='' />
                            </Link>
                        </div>
                        <div className='investimentos'>
                            <Link to='/segmentos' onClick={() => goTo("finance")}>
                                <img id='icons1' src='./INVESTIMENTOS-1.png' alt='' />
                                <img id='icons2' src='./INVESTIMENTOS-2.png' alt='' />
                            </Link>

                        </div>
                        <div className='franquias'>
                            <Link to='/segmentos' onClick={() => goTo("franquias")}>
                                <img id='icons1' src='./FRANQUIAS-1.png' alt='' />
                                <img id='icons2' src='./FRANQUIAS-2.png' alt='' />
                            </Link>
                        </div>
                    </div>
                    <div className='segmentosIcons-2'>
                        <div className='terceirizacao'>
                            <Link to='/segmentos' onClick={() => goTo("engenharia")}>
                                <img id='icons1' src='./TERCEIRIZACA-1.png' alt='' />
                                <img id='icons2' src='./TERCEIRIZACA-2.png' alt='' />
                            </Link>
                        </div>
                        <div className='telecom'>
                            <Link to='/segmentos' onClick={() => goTo("telecom")}>
                                <img id='icons1' src='./TELECOM-1.png' alt='' />
                                <img id='icons2' src='./TELECOM-2.png' alt='' />
                            </Link>
                        </div>
                        <div className='consultoria'>
                            <Link to='/segmentos' onClick={() => goTo("terceirizacao")}>
                                <img id='icons1' src='./CONSULTORIA-E-TAX-1.png' alt='' />
                                <img id='icons2' src='./CONSULTORIA-E-TAX-2.png' alt='' />
                            </Link>
                        </div>
                        <div className='social'>
                            <Link to='/esg'>
                                <img id='icons1' src='./SOCIAL-1.png' alt='' />
                                <img id='icons2' src='./SOCIAL-2.png' alt='' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}