import '../../Home/FirstSection/style.css'

export default function SegmentosFirstSection() {
    return (
        <div className='homeFirstSection'>
            <div className='opacityReductor'></div>
            <video autoPlay muted playsInline loop loading='lazy'>
            <source
              src='./institucional.mp4'
              type="video/mp4"
            />
            </video>
            <h1>O QUE FAZEMOS</h1>
            <a href='#segmentoSecondSection'><i className="fa-solid fa-angle-down"></i></a>
      </div>
    )
}