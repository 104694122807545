import "./style.css"

export default function SegmentoSegmentos(props) {
    return (
        <div className='Segmento-segmnetos' style={{ marginRight: `${props.marginRight}`, marginLeft: `${props.marginLeft}`, color: `${props.textColor}` }}>
            <span style={{ color: `${props.segmentoColor}` }}>segmento</span>
            <img src={props.segmentoIcon} alt='segmentos' />
            <h1>{props.segmentoName}</h1>
            <p>{props.segmentoText}</p>
        </div>
    )
}