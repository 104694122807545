import '../App.css'
import HomeEightSection from '../components/Home/EightSection'
import HomeFirstSection from '../components/Home/FirstSection'
import HomeFiveSection from '../components/Home/FiveSection'
import Footer from '../components/Home/Footer'
import HomeFourthSection from '../components/Home/FourthSection'
import Header from '../components/Home/Header'
import HomeSecondSection from '../components/Home/SecondSection'
// import HomeSevenSection from '../components/Home/SevenSection'
import HomeSixSection from '../components/Home/SixSection'
import HomeThirdSection from '../components/Home/ThirdSection'


export default function Home() {
  return (
    <div className='home'>
      <Header
      home='active'
      oGrupo=''
      segmentos=''
      esg=''
      contato=''
      />
      <HomeFirstSection />
      <HomeSecondSection />
      <HomeThirdSection />
      <HomeFourthSection />
      <HomeFiveSection />
      <HomeSixSection />
      {/* <HomeSevenSection /> */}
      <HomeEightSection />
      <Footer 
      home="active"
      oGrupo=''
      segmentos=''
      privacidade=''
      contato=''
      />
    </div>
  )
}