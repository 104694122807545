import '../App.css'
import Header from '../components/Home/Header'
import Footer from '../components/Home/Footer'
import EsgFirstSection from '../components/Esg/FirstSection'
import EsgSecondSection from '../components/Esg/SecondSection'
import EsgThirdSection from '../components/Esg/ThirdSection'
import EsgFourthSection from '../components/Esg/FourthSection'
import EsgFifthSection from '../components/Esg/FifthSection'
import EsgSixthSection from '../components/Esg/SixthSection'

export default function Esg() {
    return (
        <div className='esg'>
            <Header
                home=''
                oGrupo=''
                segmentos=''
                esg='active'
                contato=''
            />
            <EsgFirstSection />
            <EsgSecondSection />
            <EsgThirdSection />
            <EsgFourthSection />
            <EsgFifthSection />
            <EsgSixthSection />
            <Footer
                home=""
                oGrupo=''
                segmentos=''
                privacidade=''
                contato=''
            />
        </div>
    )
}