import './style.css'

export default function OGrupoFourfhSection() {
    return (
        <div className="oGrupoFourfhSection">
            <div className="oGrupoFourfhSection-area">
                <video 
                    loading='lazy'
                    playsInline  
                    width="80%"
                    controls
                    controlsList='nodownload'
                    >
                    <source
                        src='./institucional.mp4'
                        type="video/mp4"
                    />
                </video>
            </div>
        </div>
    )
}