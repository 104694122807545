import './style.css'

export default function EsgSecondSection() {
    return (
        <div className='esgSecondSection'>
            <div id='EsgSecondSection' className='esgSecondSection-area'>
                <div className='esgSecondSection-box'>
                    <span style={{textTransform: "uppercase"}}>responsabilidade social</span>
                    <h1>o que é esg?</h1>
                    <p>ESG é a sigla em inglês para as palavras environmental, social and governance, que, em português, podem ser traduzidas como “ambiental, social e governança”. O termo ESG é usado para descrever o quanto uma empresa procura reduzir danos ao meio ambiente, atuando de maneira sustentável, além de adotar as melhores práticas administrativas. Além disso, a sigla também é utilizada para falar de investimentos que levam em conta critérios de sustentabilidade, e não somente índices financeiros. <span>Entenda melhor o significado de cada letra que compõe a sigla ESG:</span></p>
                </div>
                <div className='esgSecondSection-box'>
                    <p><span>Environmental (ambiental):</span> A letra E da sigla diz respeito às práticas da empresa no que concerne à conservação do meio ambiente. Isso inclui a sua atuação sobre assuntos como a poluição do ar e da água, o aquecimento global e a emissão de carbono na atmosfera, o desmatamento, a escassez da água, dentre outros.</p>
                    <p><span>Social:</span> A letra S tem relação com a maneira como a empresa lida com as pessoas que fazem parte dela, e também com a comunidade do seu entorno. Isso inclui proteger os dados dos clientes, ter uma equipe diversa e engajada e respeitar a legislação trabalhista vigente.</p>
                    <p><span>Governance (governança):</span> A governança, por sua vez, tem relação com a administração da empresa, por exemplo, a sua conduta corporativa, a relação com governos, a existência de um canal de denúncias e etc.</p>
                </div>
            </div>
        </div>
    )
}