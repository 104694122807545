import './style.css'

export default function SegmentoCards(props) {
    return (
        <div className='segmentosSecondSection-boxPadrao' style={{ backgroundColor: `${props.boxColor}`, color: `${props.boxTextColor}`, margin: `${props.margin}` }} >
            <img src={props.boxIcon} alt='' />
            <p>{props.boxText}</p>
            <div className='segmentosSecondSection-button' >
                <a href={props.boxButton} target='_blank' rel='noreferrer' style={{ backgroundColor: `${props.buttonColor}`}}>visite o site <i className="fa-solid fa-arrow-right"></i></a>
            </div>
        </div>
    )
}