import CountUp from 'react-countup';
import './style.css'

export default function HomeFourthSection() {

    return (
        <div className='homeFourthSection'>
            <div className='homeFourthSection-area'>
                <img src='./ALLOYMIT_MAPAMUNDI.png' alt='' />
                <div id='anosDeAtuacao' className='floatingNumbers'>
                    <h2>+
                        <CountUp start={0} end={20} delay={0} enableScrollSpy={true}>
                        {({ countUpRef }) => (
                            <span ref={countUpRef}></span>
                        )}
                        </CountUp>
                    
                    </h2>
                    <p>anos de atuação<br />no mercado</p>
                </div>
                <div id='clientesAtendidos' className='floatingNumbers'>
                    <h2>+
                        <CountUp start={0} end={5000} delay={0} enableScrollSpy={true}>
                        {({ countUpRef }) => (
                            <span ref={countUpRef}></span>
                        )}
                        </CountUp>
                    
                    </h2>
                    <p>clientes atendidos</p>
                </div>
                <div id='colaboradoresCapacitados' className='floatingNumbers'>
                    <h2>+
                        <CountUp start={0} end={400} delay={0} enableScrollSpy={true}>
                        {({ countUpRef }) => (
                            <span ref={countUpRef}></span>
                        )}
                        </CountUp>
                    
                    </h2>
                    <p>colaboradores<br />capacitados</p>
                </div>
            </div>
        </div>
    )
}
