import './style.css'

export default function HomeFirstSection() {
    return (
        <div className='homeFirstSection'>
            <div className='opacityReductor'></div>
            <video autoPlay muted playsInline loop loading='lazy'>
            <source
              src='./institucional.mp4'
              type="video/mp4"
            />
            </video>
            <h1>somos alloymit</h1>
            <a href='#homeSecondSection'><i className="fa-solid fa-angle-down"></i></a>
      </div>
    )
}