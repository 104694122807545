import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css/pagination'
import './style.css'
import 'swiper/css'
import { useEffect, useState } from 'react';

SwiperCore.use([Autoplay]);

const imagem = [
    {imagem: './fastshop.png'},
    {imagem: './massey.png'},
    {imagem: './iss.png'},
    {imagem: './fiat.png'},
    {imagem: './besni.png'},
    {imagem: './band.png'},
    {imagem: './multilaser.png'},
]

export default function HomeFiveSection() {


    const [toPerView, setToPerView] = useState(3)

    useEffect(() => {
        if(window.innerWidth < 1115) {
            setToPerView(3)
        }
        if(window.innerWidth < 815) {
            setToPerView(2)
        }
        if(window.innerWidth < 780) {
            setToPerView(3)
        }
        if(window.innerWidth < 570) {
            setToPerView(2)
        }
    },[])

    return (
        <div className='homeFiveSection'>
            <div className='homeFiveSection-area'>
                <div className='homeFiveSection-texto'>
                    <p>quem confia no grupo</p>
                    <h2>alloymit</h2>
                </div>

                <Swiper
                        slidesPerView={toPerView}
                        grabCursor={true}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                    >

                        {imagem.map((element, index) => (
                            <SwiperSlide key={index} >

                                <img src={element.imagem} alt='' width={100} />

                            </SwiperSlide>
                        ))}

                    </Swiper>
            </div>

        </div>
    )
}