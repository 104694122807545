import './App.css';
import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import OGrupo from './pages/OGrupo';
import Segmentos from './pages/Segmentos';
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade';
import Contato from './pages/Contato';
import Esg from './pages/Esg';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/o-grupo',
    element: <OGrupo />
  },
  {
    path: '/segmentos',
    element: <Segmentos />
  },
  {
    path: '/esg',
    element: <Esg />
  },
  {
    path: '/privacidade-e-cookies',
    element: <PoliticaDePrivacidade />
  },
  {
    path: '/contato',
    element: <Contato />
  },
])

export { router }