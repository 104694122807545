export const carouselArray = [
    {
        "frase": '“O Grupo AlloyMit acredita que o poder das grandes transformações estão na capacidade de valorizar pessoas.”',
        "nome": "Denis W. Silva",
        "cargo": "Chairman",
        "foto": './ALLOYMIT_TEAM_DENISWSILVA.png'
      },
    {
        "frase": '“Alegria e prazer na criação dos empreendimentos Unity & DW”',
        "nome": 'Marcos Vernalha',
        "cargo": 'Technical Director',
        "foto": './ALLOYMIT_TEAM_MARCOSVERNALHA.png'
      },
    {
        "frase": '“O grande segredo da vida é que não há nenhum grande segredo. Seja qual for o seu objetivo, você pode chegar lá se estiver disposto a trabalhar.”',
        "nome": 'Janaina Medeiros',
        "cargo": 'Senior Partner',
        "foto": './ALLOYMIT_TEAM_JANAINAMEDEIROS.png'
      },
    {
        "frase": '“Trabalhar com o que se ama, somado ao comprometimento com a excelência, compõem a única estrada que leva ao verdadeiro sucesso. Sempre colheremos do fruto do nosso trabalho!”',
        "nome": 'Jessica Oliveira',
        "cargo": 'Legal Coordinator',
        "foto": './ALLOYMIT_TEAM_JESSICAOLIVEIRA.png'
      }
]