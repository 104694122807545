import '../App.css'
import Header from '../components/Home/Header'
import Footer from '../components/Home/Footer'
import PoliticaECookiesFirstSection from '../components/PoliticaECookies/FirstSection'

export default function PoliticaDePrivacidade() {
    return (
        <div className='politicaDePrivacidade'>
            <Header
                home=''
                oGrupo=''
                segmentos=''
                esg=''
                contato=''
            />
            <PoliticaECookiesFirstSection />
            <Footer
                home=""
                oGrupo=''
                segmentos=''
                privacidade='active'
                contato=''
            />
        </div>
    )
}