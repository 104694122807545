import '../App.css'
import SegmentosFirstSection from '../components/Segmentos/FirstSection'
import Header from '../components/Home/Header'
import SegmentosSecondSection from '../components/Segmentos/SecondSection'
import Footer from '../components/Home/Footer'
import SegmentosThirdSection from '../components/Segmentos/ThirdSection'

export default function Segmentos() {
    return (
        <div className='segmentos'>
            <Header
                home=''
                oGrupo=''
                segmentos='active'
                esg=''
                contato=''
            />
            <SegmentosFirstSection />
            <SegmentosSecondSection />
            <SegmentosThirdSection />
            <Footer
                home=""
                oGrupo=''
                segmentos='active'
                privacidade=''
                contato=''
            />
        </div>
    )
}