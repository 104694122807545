import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/css/pagination'
import './style.css'
import 'swiper/css'
import { useEffect } from 'react';
import { useState } from 'react';

SwiperCore.use([Autoplay, Pagination]);

const imagem = [
    {imagem: './ALLOYMIT_IMGS_ALDEIA_1.png'},
    {imagem: './ALLOYMIT_IMGS_ALDEIA_2.png'},
    {imagem: './ALLOYMIT_IMGS_ALDEIA_3.png'},
    {imagem: './ALLOYMIT_IMGS_ALDEIA_4.png'},
    {imagem: './ALLOYMIT_IMGS_ALDEIA_5.png'},
    {imagem: './ALLOYMIT_IMGS_ALDEIA_6.png'},
    {imagem: './ALLOYMIT_IMGS_ALDEIA_7.png'},
]

export default function EsgFourthSection() {

    const [toPerView, setToPerView] = useState(3)

    useEffect(() => {
        if(window.innerWidth < 1155) {
            setToPerView(2)
        }
    },[])

    return (
        <div className='esgFourthSection'>
            <div className='esgFourthSection-area'>
                <div className='esgFourthSection-box'>
                    <div className='esgFourthSection-text'>
                        <span>causa: empoderamento econômico</span>
                        <h1>aldeia do futuro</h1>
                        <p>Nosso compromisso destaca o apoio a projetos sociais, ambientais, culturais, esportivos e de negócios, e para validação e controle do modelo de gestão, estamos em processo de implantação de um conjunto de indicadores para a carteira de projetos socioambientais.</p>
                        <p>Entre os principais resultados atingidos estão o apoio a causas sociais e certificações de qualidade nos projetos dos empreendimentos do grupo. Os projetos sociais apoiados pelo grupo na Aldeia do Futuro beneficiam diretamente cerca de mil crianças, adolescentes e idosos por ano, acreditamos que investimento social traz alto retorno para a sociedade, pois gera impacto direto na vida dos atendidos.</p>
                    </div>
                    <div className='esgFourthSection-img'>
                        <img src='./esgFourthSection-aldeia-do-futuro.png' alt='Aldeia do Futuro' width={300} />
                    </div>
                </div>
                <div className='esgFourthSection-carousel'>
                    <Swiper
                        slidesPerView={toPerView}
                        grabCursor={true}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                        pagination={{ clickable: true }}
                    >

                        {imagem.map((element, index) => (
                            <SwiperSlide key={index} >

                                <img src={element.imagem} alt='' width={300} />

                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </div>
        </div>
    )
}